<template>
  <a-modal
    v-model="visible"
    width="500px"
    :title="modalTitle"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-form-model :model="formModel" :rules="rules" ref="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
      <a-form-model-item label="收件人" prop="name">
        <a-input v-model="formModel.name" allowClear placeholder="请输入收件人" />
      </a-form-model-item>
      <a-form-model-item label="选择地区" prop="pca">
        <a-cascader
          :options="cityData"
          placeholder="请选择地区"
          v-model="formModel.pca"
          :fieldNames="{
            label: 'district',
            value: 'district',
            children: 'children',
          }"
        />
      </a-form-model-item>
      <a-form-model-item label="详细地址" prop="address">
        <a-input v-model="formModel.address" allowClear placeholder="请输入详细地址" />
      </a-form-model-item>
      <a-form-model-item label="联系电话" prop="tel">
        <a-input v-model="formModel.tel" allowClear placeholder="请输入联系电话" />
      </a-form-model-item>
      <a-form-model-item label="邮政编码" prop="code">
        <a-input v-model="formModel.code" allowClear placeholder="请输入邮政编码" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { addAddress, updateAddress } from '@/api/vip-order'
import { getAreaTree } from '@/api/workDemand'

export default {
  data () {
    return {
      formModel: {},
      confirmLoading: false,
      edit: false,
      visible: false,
      cityData: [],
      rules: {
        name: [{ required: true, message: '请输入收件人', trigger: 'blur' }],
        pca: [{ required: true, message: '请选择地区', trigger: 'change' }],
        tel: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
            message: '手机号格式错误',
            trigger: 'blur'
          }
        ],
        code: [{ required: true, message: '请输入邮政编码', trigger: 'blur' }],
        address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }]
      }
    }
  },
  computed: {
    modalTitle () {
      return !this.edit ? '地址新增' : '地址编辑'
    }
  },
  created () {
    getAreaTree().then((res) => {
      this.cityData = res
    })
  },
  methods: {
    createModel () {
      this.formModel = {}
      this.visible = true
    },
    editModel (model) {
      console.log(model)
      this.formModel = { ...model, pca: model.pca.split('/') }
      this.edit = true
      this.visible = true
    },
    handleSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const subFun = this.edit ? updateAddress : addAddress
          this.confirmLoading = true
          subFun({
            ...this.formModel,
            enterpriseId: this.$store.getters.userInfo.enterpriseInfoDto.enterpriseId,
            pca: this.formModel.pca.join('/')
          })
            .then(() => {
              this.$message.success(`${this.modalTitle}成功`)
              this.handleCancel()
              this.$emit('success')
            })
            .finally(() => {
              this.confirmLoading = false
            })
        }
      })
    },
    handleCancel () {
      this.edit = false
      this.$refs.form.resetFields()
      this.visible = false
    }
  }
}
</script>
