<template>
  <a-modal
    v-model="visible"
    width="600px"
    title="编辑发票抬头"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-form-model :model="formModel" :rules="rules" ref="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
      <a-form-model-item label="发票抬头" prop="name">
        <a-input v-model="formModel.name" allowClear disabled placeholder="请输入发票抬头" />
      </a-form-model-item>
      <a-form-model-item label="默认发票类型" prop="baseType">
        <a-radio-group name="baseType" v-model="formModel.baseType" >
          <a-radio value="1"> 增值税普通发票 </a-radio>
          <a-radio value="2"> 增值税专用发票 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="统一社会信用代码" prop="code">
        <a-input v-model="formModel.code" allowClear placeholder="请输入统一社会信用代码" />
      </a-form-model-item>
      <a-form-model-item label="开户银行名称" prop="bankName">
        <a-input v-model="formModel.bankName" allowClear placeholder="请输入开户银行名称" />
      </a-form-model-item>
      <a-form-model-item label="基本开户账号" prop="bankCard">
        <a-input v-model="formModel.bankCard" allowClear placeholder="请输入基本开户账号" />
      </a-form-model-item>
      <a-form-model-item label="注册场所地址" prop="address">
        <a-input v-model="formModel.address" allowClear placeholder="请输入注册场所地址" />
      </a-form-model-item>
      <a-form-model-item label="注册固定电话" prop="tel">
        <a-input v-model="formModel.tel" allowClear placeholder="请输入注册固定电话" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { addInvoice, updateInvoice } from '@/api/vip-order'

export default {
  data () {
    return {
      formModel: {},
      confirmLoading: false,
      edit: false,
      visible: false,
      cityData: [],
      rules: {
        baseType: [{ required: true, message: '请选择默认发票类型', trigger: 'change' }],
        bankCard: [{ required: true, message: '请输入开户行账号', trigger: 'blur' }],
        bankName: [{ required: true, message: '请输入开户行名称', trigger: 'blur' }],
        tel: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
            message: '手机号格式错误',
            trigger: 'blur'
          }
        ],
        code: [{ required: true, message: '请输入统一社会信用代码', trigger: 'blur' }],
        address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }]
      }
    }
  },
  methods: {
    open (model) {
      this.formModel = { ...model }
      this.edit = !!model.id
      this.visible = true
    },
    handleSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const subFun = this.edit ? updateInvoice : addInvoice
          this.confirmLoading = true
          subFun({
            ...this.formModel,
            enterpriseId: this.$store.getters.userInfo.enterpriseInfoDto.enterpriseId
          })
            .then(() => {
              this.$message.success(`编辑发票抬头成功`)
              this.handleCancel()
              this.$emit('success')
            })
            .finally(() => {
              this.confirmLoading = false
            })
        }
      })
    },
    handleCancel () {
      this.edit = false
      this.$refs.form.resetFields()
      this.visible = false
    }
  }
}
</script>
