<template>
  <a-modal
    v-model="visible"
    width="800px"
    title="申请开票"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
    okText="申请开票"
    class="billing-modal"
  >
    <div class="modal-content">
      <div class="modal-content-item" style="margin-bottom: 20px">
        <div class="modal-content-item-label">开票主体：</div>
        <div class="modal-content-item-value title">鼎基智能云平台有限公司</div>
      </div>
      <div class="modal-flex">
        <div class="modal-content-item">
          <div class="modal-content-item-label">发票类型：</div>
          <div class="modal-content-item-value">
            <a-select v-model="formModel.type" style="width: 220px">
              <a-select-option value="1"> 增值税普通发票（电子 ） </a-select-option>
              <a-select-option value="2">增值税专用发票（纸质）</a-select-option>
            </a-select>
          </div>
        </div>
        <div class="modal-content-item">
          <div class="modal-content-item-label">发票抬头：</div>
          <div class="modal-content-item-value">{{ invoiceData.name }}</div>
          <a-button type="link" @click="$emit('updateInvoice')">编辑</a-button>
        </div>
      </div>
      <div class="modal-content-item" style="margin-bottom: 20px">
        <div class="modal-content-item-label required" style="width: 90px">邮寄地址选择</div>
        <div class="modal-content-item-value">
          <a-select style="width: 520px" option-label-prop="label" v-model="formModel.addressId">
            <a-select-option
              :value="item.id"
              :label="item.pca.replaceAll('/', '') + item.address"
              v-for="item in addressList"
              :key="item.id"
            >
              <div class="select-option-item">
                <div class="select-option-item-address">{{ item.pca.replaceAll('/', '') + item.address }}</div>
                <div class="select-option-item-info">{{ item.name + '：' + item.tel }}</div>
              </div>
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="base-line"></div>
      <div class="modal-flex">
        <div class="modal-content-item">
          <div class="modal-content-item-label">开票内容：</div>
          <div class="modal-content-item-value">软件服务费</div>
        </div>
        <div class="modal-content-item">
          <div class="modal-content-item-label">税率：</div>
          <div class="modal-content-item-value">6%</div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { MemberOrderInvoiceController } from '@/api/vip-order'

export default {
  props: {
    addressList: {
      type: Array,
      default: () => []
    },
    invoiceData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      formModel: {},
      confirmLoading: false,
      visible: false
    }
  },
  methods: {
    open (orderNumber) {
      if (!this.addressList.length) {
        this.$message.error('您的发票邮寄地址为空，请先完善发票邮寄地址')
      }
      const data = this.addressList[0]
      this.formModel = {
        addressId: data.id,
        orderNumber,
        type: this.invoiceData.baseType
      }
      this.visible = true
    },
    handleSubmit () {
      if (!this.invoiceData.id) {
        this.$message.error('您的发票抬头信息不完整，请先完善发票抬头信息')
        return
      }
      if (!this.formModel.type) {
        this.$message.error('请选择发票类型')
        return
      }
      if (!this.formModel.addressId) {
        this.$message.error('请选择邮寄地址')
        return
      }
      const addressData = this.addressList.find((item) => item.id === this.formModel.addressId)
      const model = {
        ...this.invoiceData,
        ...this.formModel,
        recipientAddress: addressData.pca.replaceAll('/', '') + addressData.address,
        recipientName: addressData.name,
        recipientTel: addressData.tel
      }
      delete model.id
      this.confirmLoading = true
      MemberOrderInvoiceController(model)
        .then(() => {
          this.$message.success('申请开票提交成功')
          this.$emit('success')
          this.handleCancel()
        })
        .finally(() => {
          this.confirmLoading = false
        })
    },
    handleCancel () {
      this.visible = false
    }
  }
}
</script>

<style lang="less">
.billing-modal {
  .modal-content {
    background: #f5f5f5;
    padding: 12px 24px;
    .modal-flex {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 20px;
      .modal-content-item {
        flex: 1;
      }
    }
    .base-line {
      width: 100%;
      height: 1px;
      background: rgba(0, 0, 0, 0.2);
      position: relative;
      margin-bottom: 20px;
    }
    &-item {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #666666;
      .title {
        color: #333333;
        font-size: 18px;
        font-weight: 550;
      }
      &-label {
        width: 70px;
        margin-right: 12px;
      }
    }
  }
}
</style>
