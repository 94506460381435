<template>
  <div class="vip-order-view">
    <!-- <div class="header">
      <div class="invoice-card">
        <div class="card-title">发票抬头</div>
        <div class="tips">*一个企业主体账号仅可添加一个发票信息</div>
        <div class="invoice-card-item">发票抬头：{{ invoiceData.name }}</div>
        <div class="invoice-btn" @click="$refs.invoiceForm.open(invoiceData)">编辑</div>
        <div></div>
        <div class="invoice-card-item" style="width: 50%">
          默认发票类型：{{ invoiceData.baseType === '1' ? '增值税普通发票' : '增值税专用发票' }}
        </div>
        <div class="invoice-card-item" style="width: 40%">统一社会信用代码：{{ invoiceData.code || '- -' }}</div>
        <div class="invoice-card-item" style="width: 35%">注册固定电话：{{ invoiceData.tel || '- -' }}</div>
        <div class="invoice-card-item" style="width: 40%">开户行：{{ invoiceData.bankName || '- -' }}</div>
        <div class="invoice-card-item" style="width: 35%">基本开户账号：{{ invoiceData.bankCard || '- -' }}</div>
        <div class="invoice-card-item" style="width: 80%">注册地址：{{ invoiceData.address || '- -' }}</div>
      </div>
      <div class="address-card">
        <div class="card-title">
          <span>发票邮寄地址管理</span>
          <a-button type="primary" @click="$refs.addressForm.createModel()">新增地址</a-button>
        </div>
        <a-table
          class="address-table"
          :columns="addressColumns"
          :data-source="addressData"
          :loading="addressLoading"
          row-key="id"
          :pagination="false"
          :scroll="{ y: 120 }"
        >
          <template slot="action" slot-scope="text, record">
            <a-button type="link" @click="$refs.addressForm.editModel(record)">编辑</a-button>
            <a-button type="link" @click="handleDelAddress(record)">删除</a-button>
          </template>
        </a-table>
      </div>
    </div> -->
    <div class="item_title">
      <div class="item_titleline"></div>
      会员订单
    </div>
    <div class="order-table">
      <p-table
        ref="table"
        :columns="orderColumns"
        row-key="orderNumber"
        :source-data="getData"
        :scroll="{ y: 0 }"
        extraHeight="300"
      >
        <template slot="action" slot-scope="text, record">
          <a-button
            type="link"
            @click="$refs.billingModal.open(record.orderNumber)"
            v-if="record.invoicingStatus === '待开票' || !record.invoicingStatus"
          >申请开票</a-button
          >
          <a-button
            type="link"
            @click="$refs.billingModal.open(record.orderNumber)"
            v-if="record.invoicingStatus === '开票失败'"
          >重新开票</a-button
          >
        </template>
      </p-table>
    </div>
    <billingModal
      ref="billingModal"
      :addressList="addressData"
      :invoiceData="invoiceData"
      @success="$refs.table.refresh()"
      @updateInvoice="$refs.invoiceForm.open(invoiceData)"
    />
    <invoiceForm ref="invoiceForm" @success="getInvoiceData" />
    <addressForm ref="addressForm" @success="getAddressList" />
  </div>
</template>

<script>
import billingModal from './billing-modal.vue'
import addressForm from './address-form.vue'
import invoiceForm from './invoice-form.vue'
import { getVipOrderList, getAddress, deleteAddress, getInvoice } from '@/api/vip-order'
import moment from 'moment'

export default {
  name: 'VipOrder',
  components: { billingModal, addressForm, invoiceForm },
  data () {
    return {
      addressLoading: false,
      addressData: [],
      invoiceData: {},
      addressColumns: [
        {
          dataIndex: 'name',
          key: 'name',
          title: '收件人'
        },
        {
          dataIndex: 'tel',
          key: 'tel',
          title: '联系电话'
        },
        {
          dataIndex: 'address',
          key: 'address',
          title: '寄送地址',
          customRender: (t, r) => r.pca.replaceAll('/', '') + t,
          ellipsis: true
        },
        {
          dataIndex: 'code',
          key: 'code',
          title: '邮编'
        },
        {
          dataIndex: 'action',
          key: 'action',
          title: '操作',
          scopedSlots: { customRender: 'action' }
        }
      ],
      orderColumns: [
        {
          dataIndex: 'orderNumber',
          key: 'orderNumber',
          title: '订单编号'
        },
        {
          dataIndex: 'productName',
          key: 'productName',
          title: '产品名称'
        },
        {
          dataIndex: 'createBy',
          key: 'createBy',
          title: '创建账号'
        },
        {
          dataIndex: 'price',
          key: 'price',
          title: '金额（¥）'
        },
        {
          dataIndex: 'createTime',
          key: 'createTime',
          title: '订单交易时间',
          customRender: (text) => this.formatTime(text)
        }
        // {
        //   dataIndex: 'invoicingStatus',
        //   key: 'invoicingStatus',
        //   title: '状态',
        //   customRender: text => text || '待开票'
        // },
        // {
        //   dataIndex: 'action',
        //   key: 'action',
        //   title: '操作',
        //   scopedSlots: { customRender: 'action' }
        // }
      ]
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.getAddressList()
      this.getInvoiceData()
    },
    getAddressList () {
      this.addressLoading = true
      getAddress()
        .then((res) => {
          this.addressData = res.data
        })
        .finally(() => {
          this.addressLoading = false
        })
    },
    getInvoiceData () {
      getInvoice(this.$store.getters.userInfo.enterpriseInfoDto.enterpriseId).then((res) => {
        this.invoiceData = {
          baseType: '1',
          ...(res.data || {}),
          name: this.$store.getters.userInfo.enterpriseInfoDto.enterpriseName,
          enterpriseId: this.$store.getters.userInfo.enterpriseInfoDto.enterpriseId
        }
      })
    },
    handleDelAddress ({ id }) {
      this.$confirm({
        title: '删除确认',
        content: '是否确定要邮寄地址吗，删除操作将不可撤销',
        onOk: () => {
          deleteAddress(id).then(() => {
            this.$message.success('删除成功')
            this.getAddressList()
          })
        }
      })
    },
    getData (pagination) {
      return getVipOrderList({
        ...pagination,
        enterpriseName: this.$store.getters.userInfo.enterpriseInfoDto?.enterpriseName
      })
    },
    formatTime (time) {
      return time && moment(time).format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style lang="less">
@import url('../../common/style');

.vip-order-view {
  height: 100%;
  .header {
    display: flex;
    .invoice-card {
      margin-right: 12px;
    }
    .invoice-card,
    .address-card {
      height: 228px;
      background: #ffffff;
      border-radius: 2px;
      flex: 1;
      .card-title {
        height: 42px;
        padding: 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        color: #333333;
        font-weight: 550;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .tips {
      color: #fc9a08;
      font-size: 12px;
      height: 27px;
      margin: 4px 24px 10px;
      line-height: 27px;
      background: #ffebcd;
      padding: 0 14px;
    }
    .invoice-card-item {
      margin: 0 24px;
      font-size: 12px;
      color: #666666;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .invoice-btn {
      width: 54px;
      height: 20px;
      background: #fc9a08;
      display: inline-block;
      border-radius: 18px;
      color: #ffffff;
      cursor: pointer;
      position: relative;
      top: -4px;
      text-align: center;
    }
    .address-table {
      .ant-table-placeholder {
        .ant-empty-normal {
          margin: 0 !important;
        }
      }
    }
  }
  .order-table {
    border-radius: 2px;
    height: calc(100% - 45px);
    background: #fff;
    padding: 24px;
  }
}
</style>
